import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container-fluid hero-section">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "60em" }}
      >
        <div className="col-12 align-self-center text-center">
          <h1 className="text-white">NOT FOUND</h1>
          <p className="text-white">
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
